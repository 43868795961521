@import "/var/www/pragmata/surveymanager-app/source/src/assets/styles/_variables.scss";
.HeadingIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  background-color: black;
  border: 1px solid black;
  border-radius: 4px;

  & > i {
    color: white;
  }
}
