@import "/var/www/pragmata/surveymanager-app/source/src/assets/styles/_variables.scss";
@import "../../assets/styles/variables";

.text {
  &.text-regular {
    font-weight: $regular;
  }

  &.text-medium {
    font-weight: $medium
  }

  &.text-semi-bold {
    font-weight: $semi-bold;
  }

  &.text-bold {
    font-weight: $bold;
  }
}
