@import "/var/www/pragmata/surveymanager-app/source/src/assets/styles/_variables.scss";
.Heading {
  display: flex;
  align-items: center;
  padding: 8px;
}

.HeadingIcon {
  margin-right: 10px;
}
